// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `* {
  font-family: "Roboto";
}

.content {
  min-height: calc(100vh - 80px);
  display: grid;
  gap: 60px;
}

.resolution {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 99999999;
  background-color: #fff;
  filter: opacity(70%);
  padding: 10px;
}
.resolution:hover {
  background-color: #fff;
  filter: opacity(100%);
}`, "",{"version":3,"sources":["webpack://./src/styles/visitor.scss"],"names":[],"mappings":"AAAA;EACI,qBAAA;AACJ;;AACA;EACI,8BAAA;EACA,aAAA;EACA,SAAA;AAEJ;;AAAA;EACI,eAAA;EACA,MAAA;EACA,OAAA;EACA,iBAAA;EACA,sBAAA;EACA,oBAAA;EACA,aAAA;AAGJ;AAFI;EACI,sBAAA;EACA,qBAAA;AAIR","sourcesContent":["*{\n    font-family:\"Roboto\";\n}\n.content{\n    min-height: calc( 100vh - 80px );\n    display: grid;\n    gap:60px;\n}\n.resolution{\n    position: fixed;\n    top:0;\n    left:0;\n    z-index: 99999999;\n    background-color:#fff;\n    filter:opacity(70%);\n    padding:10px;\n    &:hover{\n        background-color: #fff;\n        filter:opacity(100%);\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
