// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:root {
  --primary:#000;
  --primary-background:#B8F4FF;
  --secondary-background:rgb(46, 98, 109);
  --secondary-background-transparent:rgba(89, 163, 177,0.6);
  --secondary:#d9f9ff;
  --primary-button-background:rgb(24,83,94);
  --secondary-button-background:var(--primary);
  --secondary-button:var(--primary-background);
}

body {
  background-color: var(--primary-background);
}

* {
  margin: 0px;
}

.btn {
  border-radius: 5px;
  padding: 5px;
  border: none;
}

.btn-primary {
  background-color: var(--primary-button-background);
  color: var(--secondary);
  font-weight: bold;
}
.btn-primary:hover {
  background-color: var(--secondary-button-background);
  color: var(--secondary-button);
}

.content {
  padding: 20px;
  text-align: justify;
}`, "",{"version":3,"sources":["webpack://./src/styles/index.scss"],"names":[],"mappings":"AAAA;EACI,cAAA;EACA,4BAAA;EACA,uCAAA;EACA,yDAAA;EACA,mBAAA;EACA,yCAAA;EACA,4CAAA;EACA,4CAAA;AACJ;;AACA;EACI,2CAAA;AAEJ;;AAAA;EACI,WAAA;AAGJ;;AADA;EACI,kBAAA;EACA,YAAA;EACA,YAAA;AAIJ;;AAFA;EACI,kDAAA;EACA,uBAAA;EACA,iBAAA;AAKJ;AAJI;EACI,oDAAA;EACA,8BAAA;AAMR;;AAHA;EACI,aAAA;EACA,mBAAA;AAMJ","sourcesContent":[":root{\n    --primary:#000;\n    --primary-background:#B8F4FF;\n    --secondary-background:rgb(46, 98, 109);\n    --secondary-background-transparent:rgba(89, 163, 177,0.6);\n    --secondary:#d9f9ff;\n    --primary-button-background:rgb(24,83,94);//var(--secondary-background-transparent);\n    --secondary-button-background:var(--primary);\n    --secondary-button:var(--primary-background);\n}\nbody{\n    background-color: var(--primary-background);\n}\n*{\n    margin:0px;\n}\n.btn{\n    border-radius:5px;\n    padding:5px;\n    border:none;\n}\n.btn-primary{\n    background-color: var(--primary-button-background);\n    color:var(--secondary);\n    font-weight:bold;\n    &:hover{\n        background-color: var(--secondary-button-background);\n        color:var(--secondary-button)\n    }\n}\n.content{\n    padding:20px;\n    text-align: justify;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
